import React from 'react';
import PropTypes from 'prop-types';
import {
  Pagination as PaginationComponent,
  PaginationItem,
  PaginationLink
} from 'reactstrap';

const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {
  const onPageChange = newPage => {
    return () => {
      setCurrentPage(newPage);
    };
  };

  if (!totalPages) return null;
  return (
    <PaginationComponent
      style={{
        display: 'flex',
        margin: '10px auto',
        justifyContent: 'center',
        paddingBottom: '1.5rem'
      }}
    >
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink first onClick={onPageChange(1)} />
      </PaginationItem>
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink previous onClick={onPageChange(currentPage - 1)} />
      </PaginationItem>
      {[...Array(Math.min(5, totalPages))].map((_, index) => {
        let pageToDisplay;
        if (totalPages <= 5 || currentPage <= 3) {
          pageToDisplay = index + 1;
        } else if (currentPage >= totalPages - 2) {
          pageToDisplay = totalPages - (4 - index);
        } else {
          pageToDisplay = currentPage + index - 2;
        }

        return (
          <PaginationItem key={index} active={currentPage === pageToDisplay}>
            <PaginationLink onClick={onPageChange(pageToDisplay)}>
              {pageToDisplay}
            </PaginationLink>
          </PaginationItem>
        );
      })}
      <PaginationItem disabled={currentPage === totalPages}>
        <PaginationLink next onClick={onPageChange(currentPage + 1)} />
      </PaginationItem>
      <PaginationItem disabled={currentPage === totalPages}>
        <PaginationLink last onClick={onPageChange(totalPages)} />
      </PaginationItem>
    </PaginationComponent>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired
};
export default Pagination;
