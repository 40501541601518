import { Field, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import Select from 'react-select';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import SelectProcedureLookup from '../components/SelectProcedureLookup';
import SelectSiteCode from '../components/SelectSiteCode';
import { CREATE_SCAN_DATA, GET_SCAN_DATA, UPDATE_SCAN_DATA } from '../queries';
import {
  displaySuccess,
  logAmplitudeEvent,
  pregnancyWeekOptions,
  recommendationTypeOptions,
  scanTypeOptions,
  trimisterOptions
} from '../utilities';

const styles = {
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridRowGap: '0.3rem'
  },
  spans2: { gridColumn: '1 / span 2' },
  buttonRow: { gridColumn: '1 / span 2', textAlign: 'right' }
};

const SelectInput = ({ field, form, options }) => {
  const favorableValue = options.find(o => o.value === field.value);
  return (
    <div style={{ width: '100%' }}>
      <Select
        value={favorableValue}
        onChange={option => form.setFieldValue(field.name, option.value)}
        options={options}
      />
    </div>
  );
};

SelectInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
      ]).isRequired
    })
  ).isRequired
};

const SelectHospitalsField = ({ field, form }) => {
  return (
    <Query query={GET_SCAN_DATA} variables={{ showAll: true }}>
      {({ loading, error, data }) => {
        if (error) return <span>Error: cannot load Scans.</span>;
        let options = [];
        if (data && data.itemId) {
          options = data.itemId.map(({ name, id }) => ({
            label: name,
            value: id
          }));
        }
        const dopts = field.value
          ? field.value.map(v => options.find(h => h.value === v))
          : [];
        // console.log('h', options, field.value, dopts);

        // note: select has a key which generally is not required, the problem was that
        // defaultValue loads dynamically, during the first render there is no data so
        // it loads empty input box, when data comes back it does not update, adding a
        // key that changes when data arrives force reloads the component
        return (
          <Select
            key={dopts}
            isMulti
            placeholder="All Hospitals"
            name={field.name}
            options={options}
            onChange={selectedOpts =>
              form.setFieldValue(field.name, selectedOpts.map(o => o.value))
            }
            defaultValue={dopts}
            isLoading={loading}
          />
        );
      }}
    </Query>
  );
};

SelectHospitalsField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object
};

const FormikProcedureSelector = ({ field, form, ...props }) => (
  <SelectProcedureLookup
    value={field.value}
    onChange={v => form.setFieldValue(field.name, v)}
    {...props}
  />
);

FormikProcedureSelector.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object
};

const defaultInitValue = {
  rate: '',
  enable: false,
  itemId: 0,
  scanName: '',
  siteCode: '',
  aboutInfo: '',
  waitingTime: '',
  durationofScan: '',
  trimisterData: '',
  weekData: '',
  emailTemplate: '',
  smsTemplate: ''
};

const ScanModal = props => {
  // eslint-disable-next-line react/prop-types
  const { scanDetails, isOpen, hideModal, onSave } = props;
  let scanDataEdit = isEmpty(scanDetails)
    ? { ...defaultInitValue }
    : scanDetails;
  let itemId = scanDataEdit.itemId;

  return (
    <Mutation
      mutation={CREATE_SCAN_DATA}
      onCompleted={createData => {
        onSave(createData);
        displaySuccess('New scan created');
      }}
      refetchQueries={() => ['GET_SCAN_DATA']}
    >
      {(createScan, { loading, error }) => {
        return (
          <Mutation
            mutation={UPDATE_SCAN_DATA}
            onCompleted={updateData => {
              onSave(updateData);
              displaySuccess('Scan updated');
              logAmplitudeEvent(
                'Scan_updated',
                updateData['updateScanData'],
                true
              );
            }}
            refetchQueries={() => ['GET_SCAN_DATA']}
          >
            {(updateScan, { loading: updateLoading, error: updateError }) => {
              const errObj = error || updateError;
              const displayErr = errObj && errObj.message;
              const displayLoading = loading || updateLoading;
              return (
                <Modal isOpen={isOpen} toggle={hideModal}>
                  <ModalHeader toggle={hideModal}>
                    {itemId ? 'Edit Scan Data' : 'Create New Scan Data'}
                  </ModalHeader>
                  <ModalBody>
                    {/* <pre>{JSON.stringify(adminUser, null, 2)}</pre> */}
                    <div>
                      <Formik
                        enableReinitialize
                        initialValues={scanDataEdit}
                        onSubmit={values => {
                          const {
                            siteCode,
                            scanName,
                            aboutInfo,
                            LtProcedure,
                            appEnabled,
                            durationofScan,
                            weekData,
                            smsTemplate,
                            favouriteScan,
                            scanType,
                            recommendationType,
                            descriptionShort,
                            trimisterData,
                            emailTemplate
                          } = values;

                          const inputData = {
                            siteCode,
                            scanName,
                            aboutInfo,
                            LtProcedure,
                            appEnabled,
                            durationofScan,
                            scanType,
                            recommendationType,
                            descriptionShort,
                            weekData,
                            smsTemplate,
                            favouriteScan,
                            trimisterData,
                            emailTemplate,
                            itemId: parseInt(values.itemId),
                            rate: values.rate ? parseFloat(values.rate) : 0,
                            // durationofScan: values.durationofScan
                            //   ? parseInt(values.durationofScan)
                            //   : 0,
                            waitingTime: values.waitingTime
                              ? parseInt(values.waitingTime)
                              : 0
                            // weekData: values.weekData
                            //   ? parseInt(values.weekData)
                            //   : 0,
                            // TrimesterData: values.TrimesterData
                            //   ? parseInt(values.TrimesterData)
                            //   : 0
                          };
                          if (!itemId) {
                            createScan({ variables: { input: inputData } });
                          } else {
                            // console.log('updating');
                            const input = inputData;
                            // {
                            //   itemId,
                            //   ...values,
                            //   waitingTime: values.waitingTime
                            //     ? parseInt(values.waitingTime)
                            //     : 0,
                            //   rate: values.rate ? parseInt(values.rate) : 0
                            // };
                            delete input.apiSecret;
                            delete input.__typename;

                            updateScan({
                              variables: { input }
                            });
                          }
                        }}
                      >
                        {formik => (
                          <div>
                            {error && (
                              <div className="error">
                                Error updating scan. {displayErr}
                              </div>
                            )}
                            {/* {data && <pre>{JSON.stringify(data, null, 2)}</pre>} */}
                            <div style={styles.formContainer}>
                              <div>Scan Name</div>
                              <div>
                                <Field
                                  name="scanName"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>Item Id</div>
                              <div>
                                <Field
                                  name="itemId"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>SiteCode</div>
                              <div className="modalSiteCode">
                                <Field
                                  name="siteCode"
                                  render={({ form, field }) => {
                                    return (
                                      <SelectSiteCode
                                        selectedSiteCode={field.value}
                                        onSiteCodeSelect={v =>
                                          form.setFieldValue(field.name, v)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </div>
                              <div>About Info</div>
                              <div>
                                <Field
                                  name="aboutInfo"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>AMS Procedure</div>
                              <Field
                                name={`LtProcedure`}
                                render={({ form, field }) => {
                                  return (
                                    <SelectProcedureLookup
                                      value={field.value}
                                      onChange={v =>
                                        form.setFieldValue(field.name, v)
                                      }
                                      {...props}
                                    />
                                  );
                                }}
                              />
                              <div>Amount</div>
                              <div>
                                <Field name="rate" style={{ width: '100%' }} />
                              </div>

                              <div>Waiting Time</div>
                              <div>
                                <Field
                                  name="waitingTime"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>Scan Duration</div>
                              <div>
                                <Field
                                  name="durationofScan"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>Trimester</div>
                              <div>
                                <Field
                                  name="trimisterData"
                                  style={{ width: '100%' }}
                                  options={trimisterOptions}
                                  component={SelectInput}
                                />
                              </div>
                              <div>Scan Week</div>
                              <div>
                                <Field
                                  name="weekData"
                                  style={{ width: '100%' }}
                                  options={pregnancyWeekOptions}
                                  component={SelectInput}
                                />
                              </div>
                              <div>Email Template</div>
                              <div>
                                <Field
                                  name="emailTemplate"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>SMS Template</div>
                              <div>
                                <Field
                                  name="smsTemplate"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>Short Description</div>
                              <div>
                                <Field
                                  name="descriptionShort"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>Scan Type</div>
                              <div>
                                <Field
                                  name="scanType"
                                  style={{ width: '100%' }}
                                  options={scanTypeOptions}
                                  component={SelectInput}
                                />
                              </div>
                              <div>Recommendation Type</div>
                              <div>
                                <Field
                                  name="recommendationType"
                                  style={{ width: '100%' }}
                                  options={recommendationTypeOptions}
                                  component={SelectInput}
                                />
                              </div>
                              <div>App Enabled</div>
                              <div>
                                <Field
                                  type="checkbox"
                                  name="appEnabled"
                                  checked={formik.values.appEnabled}
                                />
                              </div>
                              <div>Favourite Scan</div>
                              <div>
                                <Field
                                  type="checkbox"
                                  name="favouriteScan"
                                  checked={formik.values.favouriteScan}
                                />
                              </div>
                              <div style={styles.buttonRow}>
                                <Button
                                  onClick={formik.submitForm}
                                  size="sm"
                                  color="primary"
                                  disabled={displayLoading}
                                >
                                  {itemId ? 'Update' : 'Create'}
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Formik>
                    </div>
                  </ModalBody>
                </Modal>
              );
            }}
          </Mutation>
        );
      }}
    </Mutation>
  );
};

ScanModal.propTypes = {
  scanDetails: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func
};

export default ScanModal;
