import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Modal } from 'reactstrap';
import { GET_HOLIDAYS, GET_HOSPITALS } from '../queries';
import AddOrEditHoliday from './AddOrEditHoliday';
import HolidayList from './HolidayList';

const HolidaysTab = () => {
  const { data: { hospitals } = { hospitals: [] }, loading } = useQuery(
    GET_HOSPITALS
  );

  const [selectedHospital, setSelectedHospital] = useState(null);
  const [modalState, setModalState] = useState({ mode: 'add', data: null });
  const [range, setRange] = useState({
    start: moment()
      .subtract(6, 'months')
      .startOf('month')
      .format('YYYY-MM-DD'),
    end: moment()
      .add(5, 'months')
      .endOf('month')
      .format('YYYY-MM-DD')
  });
  const [modelOpen, setModalOpen] = useState(false);

  const toggleModalState = () => {
    setModalOpen(ps => !ps);
  };

  const handleHospitalChange = item => {
    setSelectedHospital(item);
  };

  const handleRangeChange = (key, event) => {
    event.persist();
    validateRange();
    setRange(ps => ({ ...ps, [key]: event.target.value }));
  };

  const getOpts = () => {
    return hospitals.map(hosp => ({
      value: hosp.id,
      label: hosp.name
    }));
  };

  const validateRange = (key, value) => {
    const start = key == 'start' ? moment(value) : moment(range.start);
    const end = key == 'end' ? moment(value) : moment(range.end);
    if (start.isAfter(end)) {
      toast.error("Start date can't be greater than the end date.", {
        autoClose: 3000
      });
      return;
    }

    if (end.diff(start, 'months') > 12) {
      toast.error('Please keep the range within a year.', {
        autoClose: 3000
      });
      return;
    }
  };

  const handleAction = (action, data) => {
    if (action == 'edit') {
      setModalState({ data, mode: 'edit' });
      toggleModalState();
    } else if (action == 'add') {
      setModalState({ data: null, mode: 'add' });
      toggleModalState();
    }
  };

  return (
    <div className="container py-4">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="mb-0">National Holidays</h2>
        <button
          className="mb-0 btn btn-link"
          onClick={() => handleAction('add')}
        >
          + New Holiday
        </button>
      </div>
      <div className="d-flex flex-row justify-content-between mt-3 ">
        <div className="d-flex flex-row">
          <div className="mr-2" style={{ width: '30rem' }}>
            <Select
              value={selectedHospital}
              options={getOpts()}
              isLoading={loading}
              onChange={e => handleHospitalChange(e)}
            />
          </div>
          {selectedHospital ? (
            <Button color="danger" onClick={() => setSelectedHospital(null)}>
              Clear
            </Button>
          ) : null}
        </div>
        <div>
          <input
            type="date"
            value={range.start}
            onChange={e => handleRangeChange('start', e)}
          />
          <input
            type="date"
            value={range.end}
            onChange={e => handleRangeChange('end', e)}
          />
        </div>
      </div>
      <HolidayList
        range={range}
        hospitals={hospitals}
        handleAction={handleAction}
        selectedHospital={selectedHospital}
      />
      <Modal isOpen={modelOpen} toggle={toggleModalState}>
        <div className="p-4">
          <AddOrEditHoliday
            mode={modalState.mode}
            data={modalState.data}
            refetchQueries={[
              {
                query: GET_HOLIDAYS,
                variables: {
                  start: range.start,
                  end: range.end,
                  hospitalId: selectedHospital ? selectedHospital.value : null
                }
              }
            ]}
            hospitals={hospitals}
            handleClose={() => toggleModalState()}
          />
        </div>
      </Modal>
    </div>
  );
};

export default HolidaysTab;
