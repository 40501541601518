import React from 'react';
import Appointments from './pages/Appointments';
import DocHome from './pages/DocHome';
import Doctors from './pages/Doctors';
import UnauthorizedPage from './pages/UnauthorizedPage';

export const permissions = {
  CAN_VIEW_SLOT_PROTO: 'view_slot_proto',
  CAN_CREATE_DOC_LOGIN: 'create_doc_login',
  CAN_EDIT_DOC_MARKETING_ATTR: 'edit_doc_marketing_attr',
  CAN_EDIT_DOC_ALL: 'edit_doc_all_attr',
  VIEW_QUICK_APPOINTMENT_BOOK: 'view_quick_book'
};

const pathPermission = {
  admin: [
    '/',
    '/docs',
    '/users',
    '/logs',
    '/settings',
    '/approvals',
    '/labs',
    '/scans',
    '/protocols',
    '/coupons',
    '/dxapp',
    '/er'
  ],
  gre: ['/', '/approvals', '/protocols', '/coupons', '/dxapp', '/er'],
  customersupport: ['/', '/labs', '/scans', '/protocols', '/dxapp', '/er'],
  marketing: ['/', '/dxapp', '/er'],
  doctor: ['/', '/dxapp', '/er'],
  nurse: ['/', '/dxapp', '/er'],
  buhr: ['/', '/dxapp', '/er']
};

const subPagePermissions = {
  admin: [
    'appointments',
    'docs',
    'users',
    'logs',
    'loader',
    'settings',
    'protocols',
    'approvals',
    'labs',
    'scans',
    'dxapp',
    'er',
    'coupons'
  ],
  gre: ['appointments', 'approvals', 'protocols', 'coupons', 'dxapp', 'er'],
  customersupport: [
    'appointments',
    'labs',
    'scans',
    'protocols',
    'dxapp',
    'er'
  ],
  doctor: ['dochome', 'dxapp', 'er'],
  marketing: ['docs', 'dxapp', 'er'],
  nurse: ['appointments', 'dxapp', 'er'],
  buhr: ['docs', 'dxapp', 'er']
};

/**
 * This is a blacklist, things a role is NOT allowed!!!
 */
const blacklist: { [role: string]: string[] } = {
  admin: [permissions.CAN_EDIT_DOC_MARKETING_ATTR],
  gre: [permissions.CAN_EDIT_DOC_ALL],
  customersupport: [permissions.CAN_EDIT_DOC_ALL],
  marketing: [
    permissions.CAN_VIEW_SLOT_PROTO,
    permissions.CAN_CREATE_DOC_LOGIN
  ],
  nurse: [permissions.VIEW_QUICK_APPOINTMENT_BOOK],
  buhr: [
    permissions.CAN_VIEW_SLOT_PROTO,
    permissions.CAN_EDIT_DOC_MARKETING_ATTR,
    permissions.CAN_CREATE_DOC_LOGIN
  ]
};

const appName: { [role: string]: string } = {
  admin: 'Admin',
  gre: 'FrontDesk',
  customersupport: 'Care',
  marketing: 'Marketing',
  doctor: 'House',
  nurse: 'Nurse',
  buhr: 'BU HR'
};

const getLoggedInUser = () =>
  localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

/**
 * @param {string[]} permissions
 */
export const isPermitted = (permissions: string[]) => {
  const user = getLoggedInUser();
  if (!user || !user.role || !blacklist[user.role]) {
    return false;
  }

  if (
    blacklist[user.role].some(blackListedAction =>
      permissions.includes(blackListedAction)
    )
  ) {
    return false;
  }

  return true;
};

export const getHomePage = () => {
  const user = getLoggedInUser();
  if (!user || !user.role) {
    return <UnauthorizedPage />;
  }

  switch (user.role) {
    case 'marketing':
      return <Doctors />;
    case 'doctor':
      return <DocHome />;
    case 'buhr':
      return <Doctors />;
    default:
      return <Appointments />;
  }
};

export const getAppName = () => {
  const user = getLoggedInUser();
  if (!user || !user.role || !appName[user.role]) {
    return '';
  }

  return appName[user.role];
};

/**
 * @param {string} path to the page
 */
export const canSeePage = (path: string, canApproveVacations: Boolean) => {
  const user = getLoggedInUser();
  if (!user || !user.role || !pathPermission[user.role]) {
    return false;
  }

  if (pathPermission[user.role].includes(path) && path === '/approvals') {
    return canApproveVacations;
  } else if (pathPermission[user.role].includes(path)) {
    return true;
  }

  return false;
};

export const canSeeSubPage = (
  pageName: string,
  canApproveVacations: Boolean
) => {
  const user = getLoggedInUser();
  if (!user || !user.role || !pathPermission[user.role]) {
    return false;
  }

  if (
    (!pageName || subPagePermissions[user.role].includes(pageName)) &&
    pageName === 'approvals'
  ) {
    return canApproveVacations;
  } else if (!pageName || subPagePermissions[user.role].includes(pageName)) {
    return true;
  }

  return false;
};

export { getLoggedInUser };
