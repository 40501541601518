import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, Table } from 'reactstrap';
import Loader from '../components/Loader';
import { showConfirmation } from '../ConfirmationDialogService';
import { DELETE_HOLIDAY, GET_HOLIDAYS } from '../queries';

export default function HolidayList({
  range,
  selectedHospital,
  hospitals,
  handleAction
}) {
  const { data: { holidays } = { holidays: [] }, loading } = useQuery(
    GET_HOLIDAYS,
    {
      variables: {
        start: range.start,
        end: range.end,
        hospitalId: selectedHospital ? selectedHospital.value : null
      }
    }
  );

  const [del] = useMutation(DELETE_HOLIDAY, {
    refetchQueries: [
      {
        query: GET_HOLIDAYS,
        variables: {
          start: range.start,
          end: range.end,
          hospitalId: selectedHospital ? selectedHospital.value : null
        }
      }
    ],
    onCompleted: function() {
      toast.success('Delete holiday successfully', {
        autoClose: 3000
      });
    },
    onError: function(error) {
      toast.error(error.message, {
        autoClose: 3000
      });
    }
  });

  if (loading) return <Loader />;

  if (!holidays.length)
    return (
      <div className="mt-5">
        <h4>No holidays present!</h4>
      </div>
    );

  const hospitalsMap = hospitals
    ? new Map(hospitals.map(item => [item.id, item.name]))
    : new Map();

  const openDeleteConfirmationPopup = data =>
    showConfirmation(
      'Delete holiday',
      <div>
        <span>
          Permanently delete the holiday on{' '}
          <strong style={{ color: 'red' }}>
            {moment(data.date).format('DD-MM-YYYY')}
          </strong>
          ?<br />
          <span>
            Hospitals:
            {!data.hospitalIds || !data.hospitalIds.length
              ? 'All'
              : data.hospitalIds.reduce((prev, curr) => {
                  return prev + (prev ? ',\n' : '') + hospitalsMap.get(curr);
                }, '')}
          </span>
          <br />
          <strong>THIS PROCESS IS IRREVERSIBLE!</strong>
          <br />
        </span>
      </div>,
      () => del({ variables: { id: data.id } })
    );

  return (
    <div className="mt-5">
      <div className="text-right">{`${holidays.length} records`}</div>
      <Table size="sm" striped responsive>
        <thead>
          <tr>
            <th md={1}>Sl. No.</th>
            <th md={2}>Date</th>
            <th md={2}>Description</th>
            <th md={2}>Hospitals</th>
            <th md={2}>Action</th>
          </tr>
        </thead>
        <tbody>
          {holidays.map((item, index) => {
            const { id, date, description, hospitalIds } = item;
            let hospitals = 'All';
            if (hospitalIds && hospitalIds.length) {
              hospitals = hospitalIds.reduce((prev, curr) => {
                return prev + (prev ? ',\n' : '') + hospitalsMap.get(curr);
              }, '');
            }

            return (
              <tr key={id}>
                <td>{index + 1}</td>
                <td>{moment(date).format('DD-MM-YYYY')}</td>
                <td>{description || ''}</td>
                <td>{hospitals}</td>
                <td>
                  <Button
                    color="link"
                    onClick={() => handleAction('edit', item)}
                  >
                    <FaEdit />
                  </Button>
                  <Button
                    color="link"
                    onClick={() => openDeleteConfirmationPopup(item)}
                  >
                    <FaTrash />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

HolidayList.propTypes = {
  selectedHospital: PropTypes.object,
  range: PropTypes.object,
  hospitals: PropTypes.array,
  handleAction: PropTypes.func
};
