import classnames from 'classnames';
import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import AuthRequired from '../components/AuthRequired';
import CitiesTab from '../components/CitiesTab';
import HolidaysTab from '../components/HolidaysTab';
import HospitalForm from '../components/HospitalForm';
import SiteCodeTab from '../components/SiteCodeTab';

const TABS = {
  hos: 'Hospitals',
  hol: 'Holidays',
  cities: 'Cities',
  siteCode: 'SiteCode'
};

const Settings = () => {
  const [activeTab, setActiveTab] = useState(TABS.hos);

  return (
    <AuthRequired pageName="settings">
      <div className="settings-page">
        <div className="settings-sidebar">
          <Nav tabs vertical pills className="page-left-menu">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === TABS.hos })}
                onClick={() => setActiveTab(TABS.hos)}
              >
                {TABS.hos}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === TABS.hol })}
                onClick={() => setActiveTab(TABS.hol)}
              >
                {TABS.hol}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === TABS.cities })}
                onClick={() => setActiveTab(TABS.cities)}
              >
                {TABS.cities}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === TABS.siteCode })}
                onClick={() => setActiveTab(TABS.siteCode)}
              >
                {TABS.siteCode}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="settings-main">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={TABS.hos}>
              <HospitalForm />
            </TabPane>
            <TabPane tabId={TABS.hol}>
              <HolidaysTab />
            </TabPane>
            <TabPane tabId={TABS.cities}>
              <CitiesTab />
            </TabPane>
            <TabPane tabId={TABS.siteCode}>
              <SiteCodeTab />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </AuthRequired>
  );
};

export default Settings;
