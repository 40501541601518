import React, { Component } from 'react';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap';
import {
  NavLink as RRNavLink,
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
// import Appointments from '../pages/Appointments';
import Doctors from '../pages/Doctors';
import DataLoad from '../pages/DataLoad';
import { Query } from 'react-apollo';
import { GET_LOGGED_IN_USER } from '../queries/index';
import Logs from '../pages/Logs';
import AdminUsers from '../pages/AdminUsers';
import preval from 'preval.macro';
import PropTypes from 'prop-types';
import ErrorReport from './ErrorReport';
import ReportBugModal from './ReportBugModal';
import LoggedInUserSettings from './LoggedInUserSettings';
import { FaUserCog } from 'react-icons/fa';
import { getHomePage, getAppName, canSeePage } from '../permissions';
import AuthRequired from './AuthRequired';
import Settings from '../pages/Settings';
import FoOhFoBanner from './FoOhFoBanner';
import { getFirstNameUpperCase, logAmplitudeEvent } from '../utilities';
import Approval from '../pages/Approval';
import Labs from '../pages/Labs';
import Scans from '../pages/Scans';
import Protocols from '../pages/Protocols';
import Coupons from '../pages/Coupons';
import DxApp from '../pages/DxAppDashboard';
import ERAppointments from '../pages/ERAppointments';

const DisplayNav = ({ path, label, canApproveVacations }) => {
  if (!canSeePage(path, canApproveVacations)) {
    return null;
  }

  return (
    <NavItem>
      <NavLink
        exact={path === '/'}
        to={path}
        activeClassName="active"
        tag={RRNavLink}
        onClick={() => {
          const eventType = `${label}_Tab_Pressed`;
          logAmplitudeEvent(eventType, {}, true);
        }}
      >
        {label}
      </NavLink>
    </NavItem>
  );
};

DisplayNav.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  canApproveVacations: PropTypes.bool
};

export default class TopNav extends Component {
  state = { userPopoverOpen: false, isUserSettingsOpen: false };
  toggleUserPopover = () =>
    this.setState({
      ...this.state,
      userPopoverOpen: !this.state.userPopoverOpen
    });

  toggleUserSettingModal = () => {
    this.setState({
      isUserSettingsOpen: !this.state.isUserSettingsOpen,
      userPopoverOpen: false
    });
  };

  render() {
    const buildinfo = preval`module.exports = new Date().toLocaleString();`;

    return (
      <Router>
        <div>
          <Query query={GET_LOGGED_IN_USER}>
            {({ client, data: { me } = {} }) => {
              if (!me) return null;

              const { userPopoverOpen } = this.state;
              const { name, email, role, canApproveVacations } = me;
              const fn = getFirstNameUpperCase(name);
              const firstName = fn.length > 2 ? fn : 'Account';
              return (
                <Navbar color="light" light expand="md">
                  <NavbarBrand
                    to="/"
                    // activeClassName="active"
                    tag={RRNavLink}
                    className="c9-brand"
                    exact={true}
                  >
                    <span>C9</span>
                    <span>{getAppName()}</span>
                  </NavbarBrand>

                  <Nav className="ml-auto">
                    <LoggedInUserSettings
                      isOpen={this.state.isUserSettingsOpen}
                      toggle={this.toggleUserSettingModal}
                    />
                    <DisplayNav label="Home" path="/" role={role} />
                    <DisplayNav label="Doctors" path="/docs" role={role} />
                    <DisplayNav label="Users" path="/users" role={role} />
                    <DisplayNav label="Logs" path="/logs" role={role} />
                    <DisplayNav
                      label="Approvals"
                      path="/approvals"
                      role={role}
                      canApproveVacations={canApproveVacations}
                    />
                    <DisplayNav label="Labs" path="/labs" role={role} />
                    <DisplayNav label="Scans" path="/scans" role={role} />
                    <DisplayNav label="Settings" path="/settings" role={role} />
                    <DisplayNav
                      label="Protocols"
                      path="/protocols"
                      role={role}
                    />
                    <NavItem>
                      <ReportBugModal />
                    </NavItem>
                    <NavItem>
                      <Button
                        color="link"
                        id="userInfo"
                        onClick={this.toggleUserPopover}
                      >
                        {firstName}
                      </Button>
                      <Popover
                        placement="bottom"
                        isOpen={userPopoverOpen}
                        target="userInfo"
                        toggle={this.toggleUserPopover}
                        trigger="legacy"
                      >
                        <PopoverHeader>{name}</PopoverHeader>
                        <PopoverBody>
                          <p>{email}</p>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Button
                              size="sm"
                              onClick={() => {
                                localStorage.clear();
                                client.clearStore();
                                window.location.reload();
                              }}
                            >
                              Logout
                            </Button>
                            <Button
                              color="link"
                              onClick={this.toggleUserSettingModal}
                            >
                              <FaUserCog size={28} />
                            </Button>
                          </div>
                          <div className="text-right">
                            <small>Build: {buildinfo}</small>
                          </div>
                        </PopoverBody>
                      </Popover>
                    </NavItem>
                  </Nav>
                </Navbar>
              );
            }}
          </Query>

          <AuthRequired>
            <ErrorReport>
              <Switch>
                <Route exact path="/" render={getHomePage} />
                <Route path="/docs" component={Doctors} />
                <Route path="/loader" component={DataLoad} />
                <Route path="/logs" component={Logs} />
                <Route path="/users" component={AdminUsers} />
                <Route path="/settings" component={Settings} />
                <Route path="/protocols" component={Protocols} />
                <Route path="/approvals" component={Approval} />
                <Route path="/labs" component={Labs} />
                <Route path="/scans" component={Scans} />
                <Route path="/coupons" component={Coupons} />
                <Route path="/dxapp" component={DxApp} />
                <Route path="/er" component={ERAppointments} />
                <Route component={FoOhFoBanner} />
              </Switch>
            </ErrorReport>
          </AuthRequired>
        </div>
      </Router>
    );
  }
}
